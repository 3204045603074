import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { privacyStyles } from './privacy.style';
import classNames from 'classnames';

import { Main, Section, SectionContainer } from '../../layouts';
import { PageTitle, IntroImage, HelmetComponent } from '../../components';
import { OurNetwork, HowItWorks, Advantages, ContactUs } from '../../sections';

export const Privacy = ({i18n}) => {
  const classes = privacyStyles();
  const TRANSLATE_PATH = 'privacy';
  const { t } = useTranslation();
  const list = t(`${TRANSLATE_PATH}.list`, { returnObjects: true});
  const details = t(`${TRANSLATE_PATH}.details`, { returnObjects: true});
  
  return (
	<>
		<HelmetComponent sectionHead={TRANSLATE_PATH} />
		<Main>
		  <PageTitle sectionHead={TRANSLATE_PATH} />
		  <Section className={classes.main}>
			<p>{t(`${TRANSLATE_PATH}.date`)}</p>
			<ol className={classes.olTop}>
				{list.map(({ _id, title, text, sublist}, index) => {
					return (
						<li key={_id} className={classes.topList}>
							{title}
							{text != "" &&
								<Trans defaults={text} className={classes.text} parent='p' i18n={i18n}>
									<br /><strong></strong><a></a>
								</Trans>
							}
							{sublist.length > 0 &&
								<ul className={classes.ol}>
									{sublist.map(({ _id, text}) => {
										return (
											<li key={_id} className={classes.text}>
												<Trans defaults={text} i18n={i18n}>
													<br /><strong></strong><a></a>
												</Trans>
											</li>
										);
									})}
								</ul>
							}
						</li>
					);
				})}
			</ol>
		  </Section>
		</Main>
	</>
  );
};
