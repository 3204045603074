import { all, take, fork } from 'redux-saga/effects';
import * as API from '../../api';
import { execRequest } from '../../services/util';
import * as c from '../constants/common';


function* getAllAMetaFields() {
	while (true) {
		yield take(c.GET_META_FIELDS_REQUEST);
		
		yield fork(execRequest, {
			types: [
				c.GET_META_FIELDS_SUCCESS,
				c.GET_META_FIELDS_FAIL
			],
			api: API.getMetaFields,
		})
	}
}

function* getContractTypes() {
	while (true) {
		yield take(c.GET_CONTRACT_TYPES_REQUEST);
		
		yield fork(execRequest, {
			types: [
				c.GET_CONTRACT_TYPES_SUCCESS,
				c.GET_CONTRACT_TYPES_FAIL
			],
			api: API.getContractTypes,
		})
	}
}

function* getJobTypes() {
	while (true) {
		yield take(c.GET_JOB_TYPES_REQUEST);
		
		yield fork(execRequest, {
			types: [
				c.GET_JOB_TYPES_SUCCESS,
				c.GET_JOB_TYPES_FAIL
			],
			api: API.getJobTypes,
		})
	}
}

function* getJobShiftTypes() {
	while (true) {
		yield take(c.GET_JOB_SHIFT_TYPES_REQUEST);
		
		yield fork(execRequest, {
			types: [
				c.GET_JOB_SHIFT_TYPES_SUCCESS,
				c.GET_JOB_SHIFT_TYPES_FAIL
			],
			api: API.getJobShiftTypes,
		})
	}
}

function* getPositions() {
	while (true) {
		yield take(c.GET_POSITIONS_REQUEST);
		
		yield fork(execRequest, {
			types: [
				c.GET_POSITIONS_SUCCESS,
				c.GET_POSITIONS_FAIL
			],
			api: API.getPositions,
		})
	}
}

function* watch() {
	yield all([
		fork(getAllAMetaFields),
		fork(getContractTypes),
		fork(getJobTypes),
		fork(getJobShiftTypes),
		fork(getPositions),
	]);
}

export default watch;
