const prefix = 'common';

export const GET_META_FIELDS_REQUEST = `${prefix}GET_META_FIELDS_REQUEST`;
export const GET_META_FIELDS_SUCCESS = `${prefix}GET_META_FIELDS_SUCCESS`;
export const GET_META_FIELDS_FAIL = `${prefix}GET_META_FIELDS_FAIL`;

export const GET_CONTRACT_TYPES_REQUEST = `${prefix}GET_CONTRACT_TYPES_REQUEST`;
export const GET_CONTRACT_TYPES_SUCCESS = `${prefix}GET_CONTRACT_TYPES_SUCCESS`;
export const GET_CONTRACT_TYPES_FAIL = `${prefix}GET_CONTRACT_TYPES_FAIL`;

export const GET_JOB_TYPES_REQUEST = `${prefix}GET_JOB_TYPES_REQUEST`;
export const GET_JOB_TYPES_SUCCESS = `${prefix}GET_JOB_TYPES_SUCCESS`;
export const GET_JOB_TYPES_FAIL = `${prefix}GET_JOB_TYPES_FAIL`;

export const GET_JOB_SHIFT_TYPES_REQUEST = `${prefix}GET_JOB_SHIFT_TYPES_REQUEST`;
export const GET_JOB_SHIFT_TYPES_SUCCESS = `${prefix}GET_JOB_SHIFT_TYPES_SUCCESS`;
export const GET_JOB_SHIFT_TYPES_FAIL = `${prefix}GET_JOB_SHIFT_TYPES_FAIL`;

export const GET_POSITIONS_REQUEST = `${prefix}GET_POSITIONS_REQUEST`;
export const GET_POSITIONS_SUCCESS = `${prefix}GET_POSITIONS_SUCCESS`;
export const GET_POSITIONS_FAIL = `${prefix}GET_POSITIONS_FAIL`;
