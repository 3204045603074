import { makeStyles } from '@material-ui/core/styles';

export const consentStyles = makeStyles(theme => ({
	h2: {
		fontFamily: "OpenSans",
		fontSize: "2em",
		lineHeight: 1.25,
		fontWeight: 600,
		[theme.breakpoints.up('sm')]: {
			padding: "0 30px"
		}
	},
	p: {
		fontFamily: "OpenSans",
		fontSize: "1em",
		lineHeight: 1.63,
		[theme.breakpoints.up('sm')]: {
			padding: "0 30px"
		}
	}
}));