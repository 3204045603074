import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { authLogOut } from '../../../redux/actions/auth';

const useStyles = makeStyles((theme) => {
	return {
		avatar: {
			boxShadow: '0 4px 10px 0 rgba(51, 60, 77, 0.07)',
			border: '3px solid #ffffff',
			height: theme.spacing(11),
			marginRight: theme.spacing(3),
			width: theme.spacing(11),
		},
		userMenu: {
			'& *': {
				color: `${theme.colors.textLight} !important`,
			},
			'& >div': {
				paddingBottom: theme.spacing(1),
				paddingTop: theme.spacing(1),
				paddingRight: 0,
			},
		}
	}
});

const UserMenu = ({userInfo, logout}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const classes = useStyles();
	const {t} = useTranslation();
	
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	
	const handleClose = () => {
		setAnchorEl(null);
	};
	
	return (
		<div className={classes.userMenu}>
			<ListItem
				button
				onClick={handleClick}
			>
				<ListItemAvatar>
					<Avatar
						className={classes.avatar}
					/>
				</ListItemAvatar>
				
				<ListItemText
					disableTypography
					primary={
						<Typography variant="body2">
							{`${userInfo.firstName} ${userInfo.lastName}`}
						</Typography>
					}
				/>
				
				<IconButton
					size="small"
					disableRipple
					disableFocusRipple
					disableTouchRipple
					style={{
						marginLeft: 16,
					}}
				>
					<ArrowDown/>
				</IconButton>
			</ListItem>
			
			
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					horizontal: 'center',
					vertical: 'bottom',
				}}
			>
				<MenuItem
					onClick={() => {
						logout();
						handleClose();
					}}
				>
					{t('auth.Logout')}
				</MenuItem>
			</Menu>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	logout: () => dispatch(authLogOut()),
});

export default connect(
	null,
	mapDispatchToProps,
)(UserMenu);
