import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => {
	return {
		root: (props) => ({
			height: props.margin === 'dense' ? 44 : 56,
			border: '1px solid #e3e5e8',
			borderRadius: 6,
			boxShadow: 'inset 0 1px 3px 1px rgba(51, 60, 77, 0.06)',
			boxSizing: 'border-box',
			fontSize: 17,
			fontWeight: 600,
		}),
		light: {
			color: theme.colors.textLight,
		},
	};
});

const RenderSelectField = (props) => {
	const {
		className,
		emptyOptionLabel,
		formik,
		hideEmptyOption,
		label,
		name,
		showInlineLabel,
		type = 'text',
		variant = 'outlined',
		externalValue,
		options,
		onChange,
		...rest
	} = props;
	
	const classes = useStyles(props);
	
	const hasError = (formik.submitCount || formik.touched[name]) && formik.errors[name];
	
	const renderValue = (value, options = []) => {
		const displayValue = options.find(o => o.value === value);
		return (
			<Typography
				className="flex space-between"
				noWrap
			>
				<span className={classes.light}>{label}</span>
				&nbsp;
				<Typography
					component="span"
					noWrap
				>
					{(displayValue && displayValue.label) || emptyOptionLabel}
				</Typography>
			</Typography>
		);
	}
	
	const renderOptions = (options) => {
		const menuOptions = [
			...(options).map((option) => (
				<MenuItem
					key={option.value}
					value={`${option.value}`}
				>
					{option.label}
				</MenuItem>
			)),
		];
		
		if (!hideEmptyOption) {
			menuOptions.unshift((
				<MenuItem key="default_value" value="" className={classes.light}>
					{emptyOptionLabel}
				</MenuItem>
			));
		}
		
		return menuOptions;
	}
	
	const selectProps = {
		classes: {
			root: clsx(classes.root, formik.values[name] ? '' : classes.light),
		},
		displayEmpty: true,
		fullWidth: true,
		name,
		onBlur: formik.handleBlur,
		onChange: onChange && typeof onChange === 'function'
			? onChange
			: formik.handleChange,
		value: `${externalValue || formik.values[name]}`,
		variant,
	};
	
	if (label && showInlineLabel) {
		selectProps.renderValue = (value) => renderValue(value, options);
	}
	
	return (
		<FormControl
			error={!!hasError}
			fullWidth
		>
			{
				label && !showInlineLabel
					? (
						<InputLabel>{label}</InputLabel>
					)
					: null
			}
			
			<Select {...selectProps} {...rest}>
				{renderOptions(options)}
			</Select>
			
			{
				hasError
					? (
						<FormHelperText>{hasError}</FormHelperText>
					)
					: null
			}
		</FormControl>
	);
};

RenderSelectField.propTypes = {
	className: PropTypes.string,
	formik: PropTypes.object.isRequired,
	emptyOptionLabel: PropTypes.string,
	hideEmptyOption: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.any.isRequired,
		label: PropTypes.string.isRequired,
	})).isRequired,
	showInlineLabel: PropTypes.bool,
	type: PropTypes.string,
	variant: PropTypes.string,
}

export default RenderSelectField;
