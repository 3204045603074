const prefix = 'Agency/';

export const GET_AGENCY_JOBS_REQUEST = `${prefix}GET_AGENCY_JOBS_REQUEST`;
export const GET_AGENCY_JOBS_SUCCESS = `${prefix}GET_AGENCY_JOBS_SUCCESS`;
export const GET_AGENCY_JOBS_FAIL = `${prefix}GET_AGENCY_JOBS_FAIL`;

export const FIND_AGENCY_JOBS_REQUEST = `${prefix}FIND_AGENCY_JOBS_REQUEST`;
export const FIND_AGENCY_JOBS_SUCCESS = `${prefix}FIND_AGENCY_JOBS_SUCCESS`;
export const FIND_AGENCY_JOBS_FAIL = `${prefix}FIND_AGENCY_JOBS_FAIL`;

export const GET_AGENCY_JOB_DETAILS_REQUEST = `${prefix}GET_AGENCY_JOB_DETAILS_REQUEST`;
export const GET_AGENCY_JOB_DETAILS_SUCCESS = `${prefix}GET_AGENCY_JOB_DETAILS_SUCCESS`;
export const GET_AGENCY_JOB_DETAILS_FAIL = `${prefix}GET_AGENCY_JOB_DETAILS_FAIL`;

export const GET_AGENCY_APPLIED_JOBS_REQUEST = `${prefix}GET_AGENCY_APPLIED_JOBS_REQUEST`;
export const GET_AGENCY_APPLIED_JOBS_SUCCESS = `${prefix}GET_AGENCY_APPLIED_JOBS_SUCCESS`;
export const GET_AGENCY_APPLIED_JOBS_FAIL = `${prefix}GET_AGENCY_APPLIED_JOBS_FAIL`;

export const APPLY_REQUEST = `${prefix}APPLY_REQUEST`;
export const APPLY_SUCCESS = `${prefix}APPLY_SUCCESS`;
export const APPLY_FAIL = `${prefix}APPLY_FAIL`;

export const GET_MY_JOBS_REQUEST = `${prefix}GET_MY_JOBS_REQUEST`;
export const GET_MY_JOBS_SUCCESS = `${prefix}GET_MY_JOBS_SUCCESS`;
export const GET_MY_JOBS_FAIL = `${prefix}GET_MY_JOBS_FAIL`;

export const CLOSE_JOB_DETAILS = `${prefix}CLOSE_JOB_DETAILS`;
