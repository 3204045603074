const prefix = 'adminOrgsPannel/';

export const GET_ORGANISATIONS_LIST_REQUEST = `${prefix}GET_ORGANISATIONS_LIST_REQUEST`;
export const GET_ORGANISATIONS_LIST_SUCCESS = `${prefix}GET_ORGANISATIONS_LIST_SUCCESS`;
export const GET_ORGANISATIONS_LIST_FAIL = `${prefix}GET_ORGANISATIONS_LIST_FAIL`;

export const DELETE_ORGANISATIONS_REQUEST = `${prefix}DELETE_ORGANISATIONS_REQUEST`;
export const DELETE_ORGANISATIONS_SUCCESS = `${prefix}DELETE_ORGANISATIONS_SUCCESS`;
export const DELETE_ORGANISATIONS_FAIL = `${prefix}DELETE_ORGANISATIONS_FAIL`;

export const GET_CURRENT_ORGANISATION_REQUEST = `${prefix}GET_CURRENT_ORGANISATION_REQUEST`;
export const GET_CURRENT_ORGANISATION_SUCCESS = `${prefix}GET_CURRENT_ORGANISATION_SUCCESS`;
export const GET_CURRENT_ORGANISATION_FAIL = `${prefix}GET_CURRENT_ORGANISATION_FAIL`;

export const ADD_NEW_ORGANISATION_REQUEST = `${prefix}ADD_NEW_ORGANISATION_REQUEST`;
export const ADD_NEW_ORGANISATION_SUCCESS = `${prefix}ADD_NEW_ORGANISATION_SUCCESS`;
export const ADD_NEW_ORGANISATION_FAIL = `${prefix}ADD_NEW_ORGANISATION_FAIL`;

export const CHANGE_CURRENT_ORGANISATION_PIC_REQUEST = `${prefix}CHANGE_CURRENT_ORGANISATION_PIC_REQUEST`;
export const CHANGE_CURRENT_ORGANISATION_PIC_SUCCESS = `${prefix}CHANGE_CURRENT_ORGANISATION_PIC_SUCCESS`;
export const CHANGE_CURRENT_ORGANISATION_PIC_FAIL = `${prefix}CHANGE_CURRENT_ORGANISATION_PIC_FAIL`;

export const CHANGE_CURRENT_ORGANISATION_REQUEST = `${prefix}CHANGE_CURRENT_ORGANISATION_REQUEST`;
export const CHANGE_CURRENT_ORGANISATION_SUCCESS = `${prefix}CHANGE_CURRENT_ORGANISATION_SUCCESS`;
export const CHANGE_CURRENT_ORGANISATION_FAIL = `${prefix}CHANGE_CURRENT_ORGANISATION_FAIL`;

export const DELETE_USERS_FOR_CURRENT_ORGANISATION_REQUEST = `${prefix}DELETE_USERS_FOR_CURRENT_ORGANISATION_REQUEST`;
export const DELETE_USERS_FOR_CURRENT_ORGANISATION_SUCCESS = `${prefix}DELETE_USERS_FOR_CURRENT_ORGANISATION_SUCCESS`;
export const DELETE_USERS_FOR_CURRENT_ORGANISATION_FAIL = `${prefix}DELETE_USERS_FOR_CURRENT_ORGANISATION_FAIL`;

export const CHANGE_CURRENT_ORGANISATION_USER_REQUEST = `${prefix}CHANGE_CURRENT_ORGANISATION_USER_REQUEST`;
export const CHANGE_CURRENT_ORGANISATION_USER_SUCCESS = `${prefix}CHANGE_CURRENT_ORGANISATION_USER_SUCCESS`;
export const CHANGE_CURRENT_ORGANISATION_USER_FAIL = `${prefix}CHANGE_CURRENT_ORGANISATION_USER_FAIL`;

export const DELETE_DEPARTMENT_FOR_CURRENT_ORGANISATION_REQUEST = `${prefix}DELETE_DEPARTMENT_FOR_CURRENT_ORGANISATION_REQUEST`;
export const DELETE_DEPARTMENT_FOR_CURRENT_ORGANISATION_SUCCESS = `${prefix}DELETE_DEPARTMENT_FOR_CURRENT_ORGANISATION_SUCCESS`;
export const DELETE_DEPARTMENT_FOR_CURRENT_ORGANISATION_FAIL = `${prefix}DELETE_DEPARTMENT_FOR_CURRENT_ORGANISATION_FAIL`;

export const ADD_NEW_DEPARTMENT_REQUEST = `${prefix}ADD_NEW_DEPARTMENT_REQUEST`;
export const ADD_NEW_DEPARTMENT_SUCCESS = `${prefix}ADD_NEW_DEPARTMENT_SUCCESS`;
export const ADD_NEW_DEPARTMENT_FAIL = `${prefix}ADD_NEW_DEPARTMENT_FAIL`;

export const CHANGE_CURRENT_DEPARTMENT_REQUEST = `${prefix}CHANGE_CURRENT_DEPARTMENT_REQUEST`;
export const CHANGE_CURRENT_DEPARTMENT_SUCCESS = `${prefix}CHANGE_CURRENT_DEPARTMENT_SUCCESS`;
export const CHANGE_CURRENT_DEPARTMENT_FAIL = `${prefix}CHANGE_CURRENT_DEPARTMENT_FAIL`;

export const GET_CURRENT_DEPARTMENT_REQUEST = `${prefix}GET_CURRENT_DEPARTMENT_REQUEST`;
export const GET_CURRENT_DEPARTMENT_SUCCESS = `${prefix}GET_CURRENT_DEPARTMENT_SUCCESS`;
export const GET_CURRENT_DEPARTMENT_FAIL = `${prefix}GET_CURRENT_DEPARTMENT_FAIL`;

export const DELETE_DEPARTMENT_USERS_FOR_CURRENT_ORGANISATION_REQUEST = `${prefix}DELETE_DEPARTMENT_USERS_FOR_CURRENT_ORGANISATION_REQUEST`;
export const DELETE_DEPARTMENT_USERS_FOR_CURRENT_ORGANISATION_SUCCESS = `${prefix}DELETE_DEPARTMENT_USERS_FOR_CURRENT_ORGANISATION_SUCCESS`;
export const DELETE_DEPARTMENT_USERS_FOR_CURRENT_ORGANISATION_FAIL = `${prefix}DELETE_DEPARTMENT_USERS_FOR_CURRENT_ORGANISATION_FAIL`;

export const INVITE_DEPARTMENT_USER_REQUEST = `${prefix}INVITE_DEPARTMENT_USER_REQUEST`;
export const INVITE_DEPARTMENT_USER_SUCCESS = `${prefix}INVITE_DEPARTMENT_USER_SUCCESS`;
export const INVITE_DEPARTMENT_USER_FAIL = `${prefix}INVITE_DEPARTMENT_USER_FAIL`;

export const GET_CURRENT_DEPARTMENT_USER_REQUEST = `${prefix}GET_CURRENT_DEPARTMENT_USER_REQUEST`;
export const GET_CURRENT_DEPARTMENT_USER_SUCCESS = `${prefix}GET_CURRENT_DEPARTMENT_USER_SUCCESS`;
export const GET_CURRENT_DEPARTMENT_USER_FAIL = `${prefix}GET_CURRENT_DEPARTMENT_USER_FAIL`;

export const CHANGE_CURRENT_DEPARTMENT_USER_REQUEST = `${prefix}CHANGE_CURRENT_DEPARTMENT_USER_REQUEST`;
export const CHANGE_CURRENT_DEPARTMENT_USER_SUCCESS = `${prefix}CHANGE_CURRENT_DEPARTMENT_USER_SUCCESS`;
export const CHANGE_CURRENT_DEPARTMENT_USER_FAIL = `${prefix}CHANGE_CURRENT_DEPARTMENT_USER_FAIL`;

export const DELETE_DEPARTMENT_CUSTOMER_FOR_CURRENT_ORGANISATION_REQUEST = `${prefix}DELETE_DEPARTMENT_CUSTOMER_FOR_CURRENT_ORGANISATION_REQUEST`;
export const DELETE_DEPARTMENT_CUSTOMER_FOR_CURRENT_ORGANISATION_SUCCESS = `${prefix}DELETE_DEPARTMENT_CUSTOMER_FOR_CURRENT_ORGANISATION_SUCCESS`;
export const DELETE_DEPARTMENT_CUSTOMER_FOR_CURRENT_ORGANISATION_FAIL = `${prefix}DELETE_DEPARTMENT_CUSTOMER_FOR_CURRENT_ORGANISATION_FAIL`;

export const INVITE_DEPARTMENT_CUSTOMER_REQUEST = `${prefix}INVITE_DEPARTMENT_CUSTOMER_REQUEST`;
export const INVITE_DEPARTMENT_CUSTOMER_SUCCESS = `${prefix}INVITE_DEPARTMENT_CUSTOMER_SUCCESS`;
export const INVITE_DEPARTMENT_CUSTOMER_FAIL = `${prefix}INVITE_DEPARTMENT_CUSTOMER_FAIL`;

export const GET_CURRENT_DEPARTMENT_CUSTOMER_REQUEST = `${prefix}GET_CURRENT_DEPARTMENT_CUSTOMER_REQUEST`;
export const GET_CURRENT_DEPARTMENT_CUSTOMER_SUCCESS = `${prefix}GET_CURRENT_DEPARTMENT_CUSTOMER_SUCCESS`;
export const GET_CURRENT_DEPARTMENT_CUSTOMER_FAIL = `${prefix}GET_CURRENT_DEPARTMENT_CUSTOMER_FAIL`;

export const CHANGE_CURRENT_DEPARTMENT_CUSTOMER_REQUEST = `${prefix}CHANGE_CURRENT_DEPARTMENT_CUSTOMER_REQUEST`;
export const CHANGE_CURRENT_DEPARTMENT_CUSTOMER_SUCCESS = `${prefix}CHANGE_CURRENT_DEPARTMENT_CUSTOMER_SUCCESS`;
export const CHANGE_CURRENT_DEPARTMENT_CUSTOMER_FAIL = `${prefix}CHANGE_CURRENT_DEPARTMENT_CUSTOMER_FAIL`;