import React, { useState } from 'react';
import ToggleIcon from 'material-ui-toggle-icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Eye from '@material-ui/icons/Visibility';
import EyeOff from '@material-ui/icons/VisibilityOff';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
	root: {
		'& input': {
			borderRadius: 6,
			'&::placeholder': {
				color: theme.colors.textLight,
				fontSize: 17,
				fontWeight: 600,
				opacity: 1,
			},
		}
	}
}))

const RenderTextField = (props) => {
	const {
		className,
		name,
		formik,
		type = 'text',
		variant = 'outlined',
		error,
		...rest
	} = props;
	const [showText, setShowText] = useState(false);
	const classes = useStyles();
	
	const hasError = error || (formik.submitCount || formik.touched[name]) && formik.errors[name];
	
	const inputProps = {
		className,
		error: !!hasError,
		fullWidth: true,
		helperText: hasError,
		name,
		onBlur: formik.handleBlur,
		onChange: formik.handleChange,
		type,
		value: formik.values[name],
		variant,
	}
	
	if (type === 'password') {
		inputProps.type = showText ? 'text' : type;
		inputProps.InputProps = {
			endAdornment: (
				<InputAdornment position="end">
					<IconButton
						onClick={() => setShowText(!showText)}
						size="small"
					>
						<ToggleIcon
							on={!!showText}
							onIcon={<EyeOff />}
							offIcon={<Eye />}
						/>
					</IconButton>
				</InputAdornment>
			)
		};
	}
	
	return (
		<TextField
			classes={{
				root: classes.root,
			}}
			{...inputProps}
			{...rest}
		/>
	);
};

RenderTextField.propTypes = {
	className: PropTypes.string,
	formik: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	type: PropTypes.string,
	variant: PropTypes.string,
}

export default RenderTextField;
