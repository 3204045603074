/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import withWidth from '@material-ui/core/withWidth';
import MenuIcon from '@material-ui/icons/Menu';
import UserMenu from './UserMenu';

const TOOLBAR_HEIGHT = 42;

const useStyles = makeStyles((theme) => ({
	root: {
		boxShadow: 'none',
		backgroundColor: 'transparent',
		marginBottom: theme.spacing(8),
		minHeight: TOOLBAR_HEIGHT,
		'& >div': {
			minHeight: TOOLBAR_HEIGHT,
		},
		'& *': {
			color: theme.palette.text.primary,
		},
	},
	menuButton: {
		'& svg': {
			color: theme.palette.text.primary,
		},
	},
}));

function AgencyToolbar({
									onOpenNavBarMobile,
									className,
								 	userInfo,
									...rest
								}) {
	const classes = useStyles();
	
	return (
		<AppBar
			{...rest}
			className={clsx(classes.root, className)}
			position="static"
		>
			<Toolbar disableGutters>
				<span className="fill-flex"/>
				
				{
					userInfo
						? (
							<UserMenu
								userInfo={userInfo}
							/>
						)
						: null
				}
				
				<Hidden mdUp>
					<IconButton
						className={classes.menuButton}
						color="primary"
						onClick={onOpenNavBarMobile}
					>
						<MenuIcon />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
}

AgencyToolbar.propTypes = {
	className: PropTypes.string,
	onOpenNavBarMobile: PropTypes.func,
};

export default withWidth()(AgencyToolbar);
