import React, { useState, useEffect } from "react";
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { bindActionCreators } from 'redux';
import { getAllUserRoles } from '../../api';
import { USER_ROLES, USER_ROLES_URL_PARAMS } from '../../constants/common';
import { authSignUp } from '../../redux/actions/auth';
import isEmpty from 'lodash/isEmpty';
import RegistrationForm from '../../components/auth/Form/Registration';
import Card from '@material-ui/core/Card';


const propTypes = {
  authSignUp: func,
  auth: object,
  local: object
};

const getUserRoleValue = (role, userRoles) => {
  let toSetUserRole;
  let roleValue = USER_ROLES.USER;
  
  const validUrlRoleInfo = Object
    .values(USER_ROLES_URL_PARAMS)
    .find((roleInfo) => roleInfo.paramValue === role);
  
  if (validUrlRoleInfo) {
    roleValue = validUrlRoleInfo.value;
  }
  
  let userRoleInfo, defaultUserRoleInfo;
  
  for (let role of userRoles) {
    if (role.name === USER_ROLES.USER) defaultUserRoleInfo = role;
    if (role.name === roleValue) userRoleInfo = role;
  }
  
  if (userRoleInfo) {
    toSetUserRole = [userRoleInfo._id]
  }
  else if (defaultUserRoleInfo) {
    toSetUserRole = [defaultUserRoleInfo._id]
  }
  
  return toSetUserRole;
};

function SignUp({ auth, authSignUp }) {
  const {
    role,
  } = useParams();
  const [userRoles, setUserRoles] = useState();
  
  const isAgency = role === USER_ROLES_URL_PARAMS.AGENCY.paramValue;
  
  
  useEffect(() => {
    getAllUserRoles()
      .then(({ data: { userRoles } }) => setUserRoles(userRoles))
      .catch(console.error)
  }, []);
  
  const handleSendSignUp = (values) => {
    const toSubmit = {
      ...values,
      organisation: [isAgency ? values.organisation : values.organisation.value],
      userRoles: getUserRoleValue(role, userRoles),
      userInfo: {
        phoneNumber: values.phoneNumber,
      },
    };
    authSignUp(toSubmit)
  }

  const displayErrorMessage = ( msg ) => {
    if(typeof(msg) !== 'object') return msg;

    const err = msg.reduce((acc, item) => {
      const str = Object.values(item.constraints).join(', ');
      acc.push(str);
      return acc;
    }, []);

    const res = err.join(', ');
    return res;
  }
  
  return (
    <>
      <Card className="auth-card">
        <RegistrationForm
          isAgency={isAgency}
          submitError={!isEmpty(auth.error) && displayErrorMessage(auth.error.message)}
          onSubmit={handleSendSignUp}
        />
      </Card>
    </>
  );
}


SignUp.propTypes = propTypes;

const mapStateToProps = (state) =>({
  local : state.local,
  auth : state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  authSignUp: bindActionCreators(authSignUp, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
