const prefix = 'adminPersonPannel/';

export const GET_PERSON_USERS_LIST_REQUEST = `${prefix}GET_PERSON_USERS_LIST_REQUEST`;
export const GET_PERSON_USERS_LIST_SUCCESS = `${prefix}GET_PERSON_USERS_LIST_SUCCESS`;
export const GET_PERSON_USERS_LIST_FAIL = `${prefix}GET_PERSON_USERS_LIST_FAIL`;

export const DELETE_PERSON_USERS_REQUEST = `${prefix}DELETE_PERSON_USERS_REQUEST`;
export const DELETE_PERSON_USERS_SUCCESS = `${prefix}DELETE_PERSON_USERS_SUCCESS`;
export const DELETE_PERSON_USERS_FAIL = `${prefix}DELETE_PERSON_USERS_FAIL`;

export const INVITE_USER_REQUEST = `${prefix}INVITE_USER_REQUEST`;
export const INVITE_USER_SUCCESS = `${prefix}INVITE_USER_SUCCESS`;
export const INVITE_USER_FAIL = `${prefix}INVITE_USER_FAIL`;

export const GET_CURRENT_PERSON_USER_REQUEST = `${prefix}GET_CURRENT_PERSON_USER_REQUEST`;
export const GET_CURRENT_PERSON_USER_SUCCESS = `${prefix}GET_CURRENT_PERSON_USER_SUCCESS`;
export const GET_CURRENT_PERSON_USER_FAIL = `${prefix}GET_CURRENT_PERSON_USER_FAIL`;

export const CHANGE_CURRENT_USER_REQUEST = `${prefix}CHANGE_CURRENT_USER_REQUEST`;
export const CHANGE_CURRENT_USER_SUCCESS = `${prefix}CHANGE_CURRENT_USER_SUCCESS`;
export const CHANGE_CURRENT_USER_FAIL = `${prefix}CHANGE_CURRENT_USER_FAIL`;