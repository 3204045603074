import React, { useEffect, useState } from 'react';
import { func, object } from 'prop-types';
import Card from '@material-ui/core/Card';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ForgotPasswordForm from '../../components/auth/Form/ForgotPassword';
import { authForgotPassword } from '../../redux/actions/auth';

const propTypes = {
  authForgotPassword: func,
  auth: object,
};


function ResetPassword({ auth, authForgotPassword }) {
  const [submitError, setSubmitError] = useState(null);
  
  const onForgotPassword = ({ email }) => {
    setSubmitError(null);
    authForgotPassword(email);
  }
  
  useEffect(() => {
    const submitError = !isEmpty(auth.error) && auth.error.message;
    
    setSubmitError(submitError);
  }, [auth])
  
  
  return (
    <>
      <Card className="auth-card">
        <ForgotPasswordForm
          submitError={submitError}
          onSubmit={onForgotPassword}
        />
      </Card>
    </>
  );
}

ResetPassword.propTypes = propTypes;

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  authForgotPassword: bindActionCreators(authForgotPassword, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
