import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';

const useStyles = makeStyles((theme) => ({
	root: {
		'& input': {
			borderRadius: 6,
			'&::placeholder': {
				color: theme.colors.textLight,
				fontSize: 17,
				fontWeight: 600,
				opacity: 1,
			},
		}
	}
}))

export default function RenderAutoComplete(props) {
	const {
		className,
		name,
		formik,
		variant = 'outlined',
		options,
		onInputChange,
		placeholder,
		error,
		...rest
	} = props;
	
	const classes = useStyles();
	const [value, setValue] = React.useState(null);
	const [inputValue, setInputValue] = React.useState('');
	
	const hasError = error || (formik.submitCount || formik.touched[name]) && formik.errors[name];
	
	const fetch = React.useMemo(
		() =>
			throttle((request) => {
				onInputChange(request);
			}, 200),
		[],
	);
	
	React.useEffect(() => {
		let active = true;
		
		if (inputValue === '') {
			onInputChange(inputValue);
			return undefined;
		}
		
		fetch(inputValue);
		
		return () => {
			active = false;
		};
	}, [value, inputValue, fetch]);
	
	return (
		<>
			<FormControl
				className={className}
				error={!!hasError}
				fullWidth
			>
				<Autocomplete
					getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
					filterOptions={(x) => x}
					options={options}
					autoComplete
					includeInputInList
					filterSelectedOptions
					value={value}
					onChange={(event, newValue) => {
						setValue(newValue);
						
						formik.setFieldValue(name, newValue ? newValue : null, true);
					}}
					onInputChange={(event, newInputValue) => {
						setInputValue(newInputValue);
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							variant={variant}
							name={name}
							placeholder={placeholder}
							onBlur={formik.handleBlur}
							className={classes.root}
							fullWidth
						/>
					)}
				/>
				
				{
					hasError
						? (
							<FormHelperText>{hasError}</FormHelperText>
						)
						: null
				}
			</FormControl>
		</>
	);
}
