import React, { useEffect } from "react";
import { connect } from "react-redux";

// import DateFnsUtils from "@date-io/date-fns";
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { LocalizationProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAuthToken } from './helpers';
import { isLoggedIn } from './redux/actions/auth';
import 'moment/locale/de';
import maTheme from "./theme";
import Routes from "./routes/Routes";
import ws from './services/ws';
function App({ theme, isLoggedIn }) {
  useEffect(() => {
    const token = getAuthToken();
    
    if (token) {
      isLoggedIn();
    }
  }, []);
  
  return (
    <StylesProvider injectFirst>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
          <ThemeProvider theme={maTheme[theme.currentTheme]}>
            <Routes />
          </ThemeProvider>
        </MuiThemeProvider>
      </LocalizationProvider>
    </StylesProvider>
  );
}

export default connect(
  (store) => ({ theme: store.themeReducer }),
  (dispatch) => ({ isLoggedIn: () => dispatch(isLoggedIn()) })
)(App);
