import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const RenderCheckBoxField = (props) => {
	const {
		className,
		name,
		formik,
		label,
		...rest
	} = props;
	
	const hasError = (formik.submitCount || formik.touched[name]) && formik.errors[name];
	
	const inputProps = {
		checked: formik.values[name],
		onChange: formik.handleChange,
		name,
	}
	
	return (
		<FormControl
			className={className}
			error={!!hasError}
		>
			<FormControlLabel
				control={<Checkbox {...inputProps} {...rest}/>}
				label={label}
			/>
			
			{
				hasError
					? (
						<FormHelperText>{hasError}</FormHelperText>
					)
					: null
			}
		</FormControl>
	);
};

RenderCheckBoxField.propTypes = {
	className: PropTypes.string,
	formik: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
}

export default RenderCheckBoxField;
