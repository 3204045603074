import { push } from "connected-react-router";
import { all, take, fork, select, put } from 'redux-saga/effects';
import { uploadDocument } from '../../api';
import * as API from '../../api';
import { getUserId, isAdmin, isAgency, isOrgAdmin } from '../../helpers';
import { execRequest } from '../../services/util';
import * as c from '../constants/agency';


function* getAllJobs() {
    while (true) {
        yield take(c.GET_AGENCY_JOBS_REQUEST);

        yield fork(execRequest, {
            types: [c.GET_AGENCY_JOBS_SUCCESS, c.GET_AGENCY_JOBS_FAIL],
            api: API.jobGet,
        });
    }
}

function* findJobs() {
    while (true) {
        const { data } = yield take(c.FIND_AGENCY_JOBS_REQUEST);

        yield fork(execRequest, {
            types: [c.FIND_AGENCY_JOBS_SUCCESS, c.FIND_AGENCY_JOBS_FAIL],
            api: API.findJobs,
            payload: data,
        });
    }
}

function* getAppliedJobs() {
    while (true) {
        yield take(c.GET_AGENCY_APPLIED_JOBS_REQUEST);

        yield fork(execRequest, {
            types: [c.GET_AGENCY_APPLIED_JOBS_SUCCESS, c.GET_AGENCY_APPLIED_JOBS_FAIL],
            api: API.getAppliedJobs,
        });
    }
}

function* getJobDetails() {
    while (true) {
        const { id } = yield take(c.GET_AGENCY_JOB_DETAILS_REQUEST);

        yield fork(execRequest, {
            types: [c.GET_AGENCY_JOB_DETAILS_SUCCESS, c.GET_AGENCY_JOB_DETAILS_FAIL],
            api: API.jobGetById,
            payload: id,
        });
    }
}

function* applyForJob() {
    while (true) {
        const { data, jobId } = yield take(c.APPLY_REQUEST);
        
        yield fork(execRequest, {
            types: [c.APPLY_SUCCESS, c.APPLY_FAIL],
            api: API.applyForJob,
            payload: {
                jobId,
                data,
            },
        });
    
        yield take([c.APPLY_SUCCESS]);
        
    
        const store = yield select();
        const language = store.local.language;
    
        yield put(push(`/${language}/agency/applied-jobs`));
    }
}


function* watch() {
    yield all([
        fork(applyForJob),
        fork(getAppliedJobs),
        fork(getAllJobs),
        fork(findJobs),
        fork(getJobDetails),
    ]);
}

export default watch;
