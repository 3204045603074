import { put, take, fork, all } from 'redux-saga/effects';
import * as API from '../../api';
import * as c from '../constants/homepage';
import { execRequest } from '../../services/util';

function* getSpecialistSearchList() {
  while (true) {
    yield take(c.SPECIALIST_SEARCH_REQUEST);

    yield fork(execRequest, {
      types: [c.SPECIALIST_SEARCH_SUCCESS, c.SPECIALIST_SEARCH_FAIL],
      api: API.specialistSearchListGet
    });
  }
};

function* sendEmailData() {
  while (true) {
    const { dateString, hospitalName, name, email, phone, specialistValue, fieldValue, isAgreed } = yield take(c.SEND_EMAIL_REQUEST);

    yield fork(execRequest, {
      types: [c.SEND_EMAIL_SUCCESS, c.SEND_EMAIL_FAIL],
      api: API.sendFormPost,
      payload: { dateString, hospitalName, name, email, phone, specialistValue, fieldValue, isAgreed }
    })
  }
}

function* adjustSpecialist() {
	while (true){
		const data = yield take(c.SPECIALIST_SEARCH_SUCCESS);
		const recv = data.data;
		const flds = {};
		let i = '';
		for (let key in recv){
		  const lng = Object.keys(recv[key].value)
		  for(let n in lng){
			  i = lng[n];
			  const fld = recv[key].fields.map((index) => {
				return index.value[i];
			  })
			  if(!flds.hasOwnProperty(i))
				  flds[i] = {}
			  flds[i][recv[key].value[i]] = { fields: fld, qual: recv[key].qualification } 
		  }
		}
		yield put({type: c.SPECIALIST_SEARCH_UPDATE, data: flds});
	}
}

function* watch() {
  yield all([
    fork(getSpecialistSearchList),
    fork(sendEmailData),
	fork(adjustSpecialist),
  ]);
}

export default watch;
