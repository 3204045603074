import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { EMAIL_PATTERN } from '../../../../constants/common';
import RenderTextField from '../../../common/formInputs/renderTextField';
import SubmitButton from '../../../common/formInputs/submitButton';

const useStyles = makeStyles((theme) => {
	return {
		root: {
			padding: theme.spacing(11, 10, 10),
			[theme.breakpoints.only('xs')]: {
				padding: theme.spacing(8, 4),
			},
			'& h1': {
				fontSize: 26,
				letterSpacing: -0.45,
				marginBottom: theme.spacing(8),
			},
		},
		submit: {
			backgroundColor: theme.colors.brandPrimary,
			height: 56,
			fontSize: 17,
			fontWeight: 600,
			marginTop: theme.spacing(1),
			minHeight: theme.spacing(13),
		},
		secondaryAction: {
			color: theme.colors.textLight,
			fontSize: 17,
			fontWeight: 600,
			height: 56,
			marginTop: 28,
		},
	};
});

const TRANSLATION_PATH = 'auth';

const LoginForm = (props) => {
	const {
		submitError,
		onSubmit,
	} = props;
	const { i18n, t: translate } = useTranslation();
	const t = (text) => text ? translate(`${TRANSLATION_PATH}.${text}`) : '';
	const classes = useStyles();
	
	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.matches(EMAIL_PATTERN, t(`Email_is_not_valid`))
			.required(t(`Email_is_not_valid`)),
		password: yup
			.string()
			.required(t(`Password_is_not_valid`)),
	});
	
	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema,
		onSubmit,
	});
	
	const renderInputContainer = (path, options = {}) => (
		<Grid item xs={12}>
			<RenderTextField
				formik={formik}
				name={path}
				{ ...options }
			/>
		</Grid>
	);
	
	return (
		<form
			className={`${classes.root}`}
			onSubmit={formik.handleSubmit}
		>
			<Grid
				container
				spacing={2}
			>
				<Grid item xs={12}>
					<Typography
						component="h1"
						variant="h2"
					>
						{t('Sign_In')}
					</Typography>
				</Grid>
				{
					renderInputContainer('email', {
						autoComplete: 'email',
						placeholder: t('Email_Address'),
						error: submitError,
					})
				}
				
				{
					renderInputContainer('password', {
						autoComplete: 'current-password',
						placeholder: t('Password'),
						type: 'password',
					})
				}
				
				<Grid item xs={12}>
					<SubmitButton
						className={classes.submit}
						size="large"
					>
						{t('Sign_In')}
					</SubmitButton>
				</Grid>
				
				<Grid item xs={12}>
					<Button
						className={classes.secondaryAction}
						fullWidth
						component={Link}
						to={`/${i18n.language}/auth/forgot-password`}
					>
						{t('Forgot_password')}
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

LoginForm.propTypes = {
	submitError: PropTypes.any,
	onSubmit: PropTypes.func.isRequired,
}

export default LoginForm;
