const prefix = 'homepage/';

export const SPECIALIST_SEARCH_REQUEST = `${prefix}SPECIALIST_SEARCH_REQUEST`;
export const SPECIALIST_SEARCH_UPDATE = `${prefix}SPECIALIST_SEARCH_UPDATE`;
export const SPECIALIST_SEARCH_SUCCESS = `${prefix}SPECIALIST_SEARCH_SUCCESS`;
export const SPECIALIST_SEARCH_FAIL = `${prefix}SPECIALIST_FAIL`;

export const ORGANISATION_SEARCH_REQUEST = `${prefix}ORGANISATION_SEARCH_REQUEST`;
export const ORGANISATION_SEARCH_SUCCESS = `${prefix}ORGANISATION_SEARCH_SUCCESS`;
export const ORGANISATION_SEARCH_FAIL = `${prefix}ORGANISATION_SEARCH_FAIL`;

export const SEND_EMAIL_REQUEST = `${prefix}SEND_EMAIL_REQUEST`;
export const SEND_EMAIL_SUCCESS = `${prefix}SEND_EMAIL_SUCCESS`;
export const SEND_EMAIL_FAIL = `${prefix}SEND_EMAIL_FAIL`;
