export const INSTITUT_LIST = [
    "UKB Berlin",
    "SDR Dressden",
    "Dusseldorff Inst"
];

export const STATION_LIST= [
    "Neurologie",
    "Urologie",
    "Cardiologie"
];

export const QUALIFICATION_LIST= [
    "Examine Pflegekraft",
    "Examine Antrage1",
    "Examine Pflegekraft2",
];

export const PERSONAL_WAHLEN_LIST= [
    "Data 1",
    "Data 2",
    "Data 3",
];