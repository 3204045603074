import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { NavLink, Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(9, 10, 8),
      [theme.breakpoints.only('xs')]: {
        padding: theme.spacing(4, 5, 5),
      },
    },
    content: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      minHeight: 'calc(100vh - 181px)',
      '& > div': {
        maxWidth: '100%',
        width: 544,
        '& .auth-card': {
          borderRadius: 6,
          boxShadow:'0 4px 10px 0 rgba(51, 60, 77, 0.07)',
        },
      },
    },
    footer: {
      marginTop: theme.spacing(9),
      '& > div': {
        minWidth: 544,
        display: 'flex',
        justifyContent: 'space-around',
        '& *': {
          color: ' #525f7a',
          fontFamily: `'OpenSans', 'sans-sarif'`,
          fontSize: 13,
          margin: theme.spacing(0, 3),
        },
      },
    },
    header: {
      display: 'flex',
      marginBottom: theme.spacing(5),
      width: '100%',
    },
    headerContent: {
      display: 'flex',
      alignItems: 'center',
      '& p': {
        color: '#525f7a',
        fontFamily: `'OpenSans', 'sans-sarif'`,
        fontSize: 14,
        marginRight: theme.spacing(4),
      },
      '& a': {
        color: theme.colors.brandPrimary,
        fontWeight: 600,
        fontSize: 17,
      },
    },
  };
})

const TRANSLATION_PATH = 'auth';
const AuthLayout = (props) => {
  const {
    children,
  } = props;
  const classes = useStyles();
  const { pathname } = useLocation();
  const { t: translate, i18n } = useTranslation();
  const t = (text) => text ? translate(`${TRANSLATION_PATH}.${text}`) : '';
  
  const isLogin = pathname.includes('sign-in') || pathname.includes('forgot-password');
  const isRegister = pathname.includes('sign-up');
  
  let headerContent = '';
  
  if (isLogin) {
    headerContent = (
      <div className={clsx(classes.headerContent, 'flex')}>
        {/*<Typography>
          {t('Dont have an account')}
        </Typography>
        
        <Typography
          component={Link}
          to={`/${i18n.language}/auth/sign-up`}
        >
          {t('Register_Here')}
        </Typography>*/}
      </div>
    );
  }
  else if (isRegister) {
    headerContent = (
      <div className={clsx(classes.headerContent, 'flex')}>
        <Typography>
          {t('Already have an account')}
        </Typography>
      
        <Typography
          component={Link}
          to={`/${i18n.language}/auth/sign-in`}
        >
          {t('Log In here')}
        </Typography>
      </div>
    );
  }
  
  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <NavLink to={`/${i18n.language}`}>
          <img
            src="/static/img/logo-hor-rgb.svg"
            alt="Logo"
          />
        </NavLink>
        
        <span className="fill-flex"/>
        
        {headerContent}
      </header>
      <main className={classes.content}>
        <div>
          { children }
        </div>
      </main>
      <footer className={clsx(classes.footer,'flex', 'justify-center')}>
        <div>
          <Typography>
            {t('Impressum')}
          </Typography>
          
          <Typography>
            {t('AGB')}
          </Typography>
          
          <Typography>
            {t('Data Protection')}
          </Typography>
          
          <Typography>
            {t('Safety')}
          </Typography>
        </div>
      </footer>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node,
};

export default AuthLayout;
