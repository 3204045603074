import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authLogOut } from '../../redux/actions/auth';
import {
  // specialistSearchRequest,
  sendForm,
} from '../../redux/actions/homepage';
import { getPositions, getAllMetaFields } from '../../redux/actions/common';

const mapStateToProps = state => ({
  local: state.local,
  specialities: { ...state.homepage.specialities },
  positions: state.common.positions,
  metaFields: state.common.metaFields,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ authLogOut, getPositions, getAllMetaFields, sendForm }, dispatch)
});

export const HomeContainer = connect(mapStateToProps, mapDispatchToProps);
