import moment from 'moment';
import capitalize from '@material-ui/core/utils/capitalize';
import { SHIFT_TYPES } from '../../../../constants/common';

export const extractOptionsWithLanguage = (optionList, i18n) => {
	return optionList && optionList.map
		? optionList.map((item) => {
			let searchInField;
			if (item['name']) searchInField = 'name';
			if (item['value']) searchInField = 'value';
			
			return {
				value: item._id,
				label: capitalize(item[searchInField][i18n.language] || item[searchInField][i18n.languages[0]]),
			}
		})
		: [];
};

export const getDateValue = (inputDate, timeString) => {
	const date = moment(inputDate);
	const time = moment(timeString, 'HH:mm');

	date.set({
		hour: time.get('hour'),
		minute: time.get('minute'),
		second: time.get('second')
	});

	return date;
}

export const onAddDate = (date, activity) => ({
	startDate: getDateValue(date, '05:30').toDate(),
	endDate: getDateValue(date, '19:30').toDate(),
	activity: activity,
});

export const isSporadic = (value) => value === SHIFT_TYPES.SPORADIC;
