import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authSignIn } from '../../redux/actions/auth';
import { func, object } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Card from '@material-ui/core/Card';
import LoginForm from '../../components/auth/Form/Login';


const propTypes = {
	authSignIn: func,
	auth: object,
};

function SignIn({auth, authSignIn}) {
	const [submitError, setSubmitError] = useState(null);
	
	const handleSendSignIn = ({ email, password }) => {
		setSubmitError(null);
		authSignIn(email, password);
	}
	
	useEffect(() => {
		const submitError = !isEmpty(auth.error) && auth.error.message;
		
		setSubmitError(submitError);
	}, [auth])
	
	
	return (
		<>
			<Card className="auth-card">
				<LoginForm
					submitError={submitError}
					onSubmit={handleSendSignIn}
				/>
			</Card>
		</>
	);
}


SignIn.propTypes = propTypes;

const mapStateToProps = (state) => ({
	auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
	authSignIn: bindActionCreators(authSignIn, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
