import * as c from '../constants/common';

export const getAllMetaFields = () => ({
	type: c.GET_META_FIELDS_REQUEST,
});

export const getContractTypes = () => ({
	type: c.GET_CONTRACT_TYPES_REQUEST,
});

export const getJobTypes = () => ({
	type: c.GET_JOB_TYPES_REQUEST,
});

export const getJobShiftTypes = () => ({
	type: c.GET_JOB_SHIFT_TYPES_REQUEST,
});

export const getPositions = () => ({
	type: c.GET_POSITIONS_REQUEST,
});
