import Container from '@material-ui/core/Container';
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => {
	return {
		root: {
			'& h2': {
				marginBottom: theme.spacing(3),
				marginTop: theme.spacing(6),
				fontSize: 26,
				fontWeight: 600,
				letterSpacing: -0.45,
			},
			'& button': {
				fontSize: 17,
				fontWeight: 600,
			},
		},
		message: {
			color: theme.colors.textLight,
			marginBottom: theme.spacing(10),
			fontFamily: `'OpenSans', 'sans-serif'`,
			fontSize: 16,
			lineHeight: 1.63,
			'& span': {
				color: theme.colors.brandPrimary,
			},
		},
	};
});



const AuthNotificationScreen = (props) => {
	const {
		action,
		error,
		icon,
		message,
		title,
		...rest
	} = props;
	const classes = useStyles();
	
	return (
		<Container className={classes.root}>
			<div className="flex full-width justify-center">
				{icon}
			</div>
			
			<Typography
				align="center"
				variant="h2"
			>
				{title}
			</Typography>
			
			{
				error
					? (
						<Typography
							gutterBottom
							component="h6"
							variant="body2"
							align="center"
							color="error"
							dangerouslySetInnerHTML={{
								__html: error,
							}}
						/>
					)
					: null
			}
			
			
			{
				typeof message === 'string'
					? (
						<Typography
							align="center"
							className={classes.message}
							dangerouslySetInnerHTML={{
								__html: message,
							}}
						/>
					)
					: message
			}
			
			<div className="flex full-width justify-center">
				{action}
			</div>
		</Container>
	);
};

AuthNotificationScreen.propTypes = {
	action: PropTypes.node,
	icon: PropTypes.node.isRequired,
	error: PropTypes.string,
	message: PropTypes.any.isRequired,
	title: PropTypes.string.isRequired,
}

export default AuthNotificationScreen;
