import { USER_ROLES } from '../constants/common';
import api from '../services/api';
import * as ENDPOINTS from '../constants/endpoints';

////// auth ////////
export const authSignUp = (data) => api.post(ENDPOINTS.SIGNUP, { ...data, role: data.role || USER_ROLES.USER, });
export const authLogIn = ({ email, password, role = "USER" }) => api.post(ENDPOINTS.SIGNIN, { email, password, role });
export const getCurrentAccount = () => api.get(`${ENDPOINTS.IS_LOGGED_IN}`);
export const forgotPassword = ({ email }) => api.get(`${ENDPOINTS.FORGOT_PASSWORD}/${email}`);
export const resendVerification = ({ email }) => api.get(`${ENDPOINTS.RESEND_VERIFICATION}/${email}`);
export const resetPassword = (data) => api.post(`${ENDPOINTS.RESET_PASSWORD}`, data);
export const confirmEmail = (data) => api.post(`${ENDPOINTS.CONFIRM_EMAIL}`, data);

////// Job ////////
export const jobGet = () => api.get(ENDPOINTS.JOB);
export const findJobs = (data) => api.post(`${ENDPOINTS.JOB}/find`, data);
export const jobPost = (job) => api.post(ENDPOINTS.JOB, job);
export const jobUpdate = (job) => api.put(`${ENDPOINTS.JOB}/${job.id}`, job);
export const jobGetById = (id) => api.get(`${ENDPOINTS.JOB}/${id}`);
export const getAppliedJobs = () => api.get(`${ENDPOINTS.JOB}/applied-jobs`);
export const applyForJob = ({ jobId, data }) => api.post(`${ENDPOINTS.JOB}/apply/${jobId}`, data);
export const removeApplication = (appliactionId) => api.delete(`${ENDPOINTS.JOB}/apply/remove/${appliactionId}`);
export const jobDel = (id) => api.delete(`${ENDPOINTS.JOB}/${id}`);

////// Orgs ///////
export const orgPost = (org) => api.post(ENDPOINTS.ORGANIZATIONS, org);
export const orgsGet = () => api.get(ENDPOINTS.ORGANIZATIONS);
export const orgGetById = (id) => api.get(`${ENDPOINTS.ORGANIZATIONS}/${id}`);
export const findOrg = (data) => api.post(`${ENDPOINTS.ORGANIZATIONS}/find`, data);

////// Home Page //////
export const specialistSearchListGet = () => api.get(ENDPOINTS.SEARCH_SPECIALITY);

////// Send Form //////
export const sendFormPost = data => api.post(ENDPOINTS.SEND_FORM, data);

////// Documents //////
export const uploadDocument = (type, data) => api.post(`${ENDPOINTS.DOCUMENTS}/${type}`, data);
export const removeDocument = (docId) => api.delete(`${ENDPOINTS.DOCUMENTS}/${docId}`);

////// Meta's //////
export const getMetaFields = () => api.get(ENDPOINTS.META_FIELDS);
export const getContractTypes = () => api.get(ENDPOINTS.CONTRACT_TYPES);
export const getJobTypes = () => api.get(ENDPOINTS.JOB_TYPES);
export const getJobShiftTypes = () => api.get(ENDPOINTS.JOB_SHIFT_TYPES);
export const getPositions = () => api.get(ENDPOINTS.POSITION);
export const getAllUserRoles = () => api.get(ENDPOINTS.USER_ROLES);
