import { combineReducers } from 'redux';
import { LOCATION_CHANGE } from "connected-react-router";
import { createLoaderReducers } from './utils';

import * as c from '../constants/auth';

const initialState = {
    userInfo: null,
    tempUserInfo: {},
    data: {},
    error: {},
};

const loaderReducers = createLoaderReducers({
    requestActions: [
        c.FORGOT_PASSWORD_REQUEST,
        c.LOG_IN_REQUEST,
        c.RESET_PASSWORD_REQUEST,
        c.RESEND_PASSWORD_REQUEST,
        c.RESEND_VERIFICATION_REQUEST,
        c.SIGN_UP_REQUEST,
    ],
    successActions: [
        c.FORGOT_PASSWORD_SUCCESS,
        c.LOG_IN_SUCCESS,
        c.RESET_PASSWORD_SUCCESS,
        c.RESEND_PASSWORD_SUCCESS,
        c.RESEND_VERIFICATION_SUCCESS,
        c.SIGN_UP_SUCCESS,
    ],
    failActions: [
        c.FORGOT_PASSWORD_FAIL,
        c.LOG_IN_FAIL,
        c.RESET_PASSWORD_FAIL,
        c.RESEND_PASSWORD_FAIL,
        c.RESEND_VERIFICATION_FAIL,
        c.SIGN_UP_FAIL,
    ],
});

const data = (state = initialState.data, action) => {
    switch (action.type) {
        case c.LOG_IN_SUCCESS:
        case c.SIGN_UP_SUCCESS:
            return { ...action.data };
        case c.LOG_IN_FAIL:
        case c.LOG_OUT_REQUEST:
        case c.SIGN_UP_FAIL:
            return {};
        default:
            return state;
    }
};

const tempUserInfo = (state = initialState.tempUserInfo, action) => {
    switch (action.type) {
        case c.SIGN_UP_SUCCESS:
        case c.RESEND_VERIFICATION_SUCCESS:
        case c.FORGOT_PASSWORD_SUCCESS:
            return { ...action.requestPayload };
        default:
            return state;
    }
};

const userInfo = (state = initialState.userInfo, action) => {
    switch (action.type) {
        case c.GET_AUTH_USER_DETAILS_SUCCESS:
            return { ...action.data };
        case c.GET_AUTH_USER_DETAILS_FAIL:
        case c.LOG_OUT_REQUEST:
            return null;
        default:
            return state;
    }
};

const authError = (state = initialState.error, action) => {
    switch (action.type) {
        case c.FORGOT_PASSWORD_SUCCESS:
        case c.LOG_IN_SUCCESS:
        case c.RESET_PASSWORD_SUCCESS:
        case c.RESEND_PASSWORD_SUCCESS:
        case c.RESEND_VERIFICATION_SUCCESS:
        case c.SIGN_UP_SUCCESS:
        case LOCATION_CHANGE:
            return {};
        case c.FORGOT_PASSWORD_FAIL:
        case c.LOG_IN_FAIL:
        case c.RESET_PASSWORD_FAIL:
        case c.RESEND_PASSWORD_FAIL:
        case c.RESEND_VERIFICATION_FAIL:
        case c.SIGN_UP_FAIL:
            return { ...action.error };
        default:
            return state;
    }
};


export default combineReducers({
    ...loaderReducers,
    userInfo,
    tempUserInfo,
    data,
    error: authError,
});
