import { combineReducers } from 'redux';
import { createLoaderReducers } from './utils';
import { LOCATION_CHANGE } from 'connected-react-router';
import * as c from '../constants/agency';

const initialState = {
	searchParams: {},
	jobInfoList: [],
	appliedJobs: [],
	error: {},
	selectedJobInfo: null,
};

const loaderReducers = createLoaderReducers({
	requestActions: [
		c.GET_AGENCY_JOBS_REQUEST,
		c.GET_AGENCY_JOB_DETAILS_REQUEST,
		c.GET_AGENCY_APPLIED_JOBS_REQUEST,
	],
	successActions: [
		c.GET_AGENCY_JOBS_SUCCESS,
		c.GET_AGENCY_JOB_DETAILS_SUCCESS,
		c.GET_AGENCY_APPLIED_JOBS_SUCCESS,
	],
	failActions: [
		c.GET_AGENCY_JOBS_FAIL,
		c.GET_AGENCY_JOB_DETAILS_FAIL,
		c.GET_AGENCY_APPLIED_JOBS_FAIL,
	],
});

const agencyData = (state = initialState, action) => {
	switch (action.type) {
		case c.FIND_AGENCY_JOBS_REQUEST:
			return {
				...state,
				searchParams: action.data,
			};
		case c.GET_AGENCY_JOBS_SUCCESS:
		case c.FIND_AGENCY_JOBS_SUCCESS:
			return {
				...state,
				jobInfoList: action.data.jobs,
			};
		case c.GET_AGENCY_APPLIED_JOBS_SUCCESS:
			return {
				...state,
				appliedJobs: action.data.appliedJobs,
			};
		case c.GET_AGENCY_JOB_DETAILS_SUCCESS:
			return {
				...state,
				selectedJobInfo: action.data.job,
			};
		case c.CLOSE_JOB_DETAILS:
		case LOCATION_CHANGE:
			return {
				...state,
				selectedJobInfo: null,
			};
		case c.GET_AGENCY_JOBS_FAIL:
		case c.FIND_AGENCY_JOBS_FAIL:
			return initialState;
		default:
			return state;
	}
};

export default combineReducers({
	agencyData,
	...loaderReducers,
});
