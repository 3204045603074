import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => {
	return {
		root: {
			backgroundColor: theme.colors.brandPrimary,
		},
	};
});
const SubmitButton = (props) => {
	const {
		children,
		className,
		...rest
	} = props;
	const classes = useStyles();
	
	return (
		<Button
			className={clsx(classes.root, className)}
			color="primary"
			fullWidth
			type="submit"
			variant="contained"
			{...rest}
		>
			{children}
		</Button>
	);
};

SubmitButton.propTypes = {
	children: PropTypes.node,
}

export default SubmitButton;
