import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHistory } from 'react-router';
import AgencySindenav, { DRAWER_WIDTH } from '../components/common/layoutSections/Sidenav';
import AgencyToolbar from '../components/common/layoutSections/Toolbar';

const useStyles = makeStyles((theme) => {
	return {
		content: {
			paddingTop: theme.spacing(3),
			paddingBottom: theme.spacing(3),
			[theme.breakpoints.up('md')]: {
				paddingTop: theme.spacing(6),
				paddingBottom: theme.spacing(6),
				paddingLeft: DRAWER_WIDTH,
			},
		},
	};
})

const AgencyLayout = (props) => {
	const {
		children,
		userInfo,
	} = props;
	const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
	const classes = useStyles();
	const history = useHistory();
	const { i18n } = useTranslation();
	
	useEffect(() => {
		if (!userInfo) {
			history.push(`/${i18n.language}`);
		}
	}, []);
	
	return (
		<>
			<AgencySindenav
				onMobileClose={() => setOpenNavBarMobile(false)}
				openMobile={openNavBarMobile}
			/>
			
			<main className={classes.content}>
				<Container maxWidth="md">
					<AgencyToolbar
						onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
						userInfo={userInfo}
					/>
					
					{ children }
				</Container>
			</main>
		</>
	);
};

AgencyLayout.propTypes = {
	children: PropTypes.node,
};

const mapStateToProps = (state) => ({
	userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps)(AgencyLayout);
