import trim from 'lodash/trim';
import moment from 'moment';
import * as CONSTANTS from '../constants/common';

const defaultLanguage = 'en';

export const getMultiLanguageValue = (item, language) => {
  return item && (item[language] || item[defaultLanguage]);
};

export function prefixPath (path, prefix) {
  return `/${prefix}/${trim(path, '/')}`
}

export const genRandomId = () => Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);

export const parseJwt = token => {

  if (token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url && base64Url.replace(/-/g, "+").replace(/_/g, "/");
    return JSON.parse(window.atob(base64));
  }
};

export const getUserEmail = () => {
  const token = JSON.parse(window.localStorage.getItem(CONSTANTS.TOKEN_KEY));
  return parseJwt(token.local.userAccess.access_token);
};

export const getUserId = () => {
  const token = JSON.parse(window.localStorage.getItem(CONSTANTS.TOKEN_KEY));
  return parseJwt(token.local.userAccess.access_token);
};

export const getAuthToken = () => {
  let authToken;
  
  const tokens = JSON.parse(localStorage.getItem('redux')).local.userAccess.access_token;
  
  if (!!tokens) {
    authToken= `${tokens.tokenType || 'Bearer'} ${tokens}`;
  }
  
  return authToken;
}

export const getAddressFromDO  = (data) => {
  const addressFields = [];
  
  if (data?.address) addressFields.push(data.address);
  if (data?.postcode) addressFields.push(data.postcode);
  if (data?.city) addressFields.push(data.city);
  if (data?.country) addressFields.push(data.country);
  
  return addressFields.join(', ');
}

const formatDate = (date) => {
  return moment(date).format('MMM DD YYYY');
}

export const getDisplayJobTitle = (title) => {
  const [
    position,
    field,
    dates
  ] = title.split('|');
  
  const [
    startDate,
    endDate,
  ] = dates
    .split(' - ')
  
  
  return [
    position,
    field,
    ` ${formatDate(startDate.trim())} - ${formatDate(endDate.trim())}`
  ].join('|');
};

const isUser = (userInfo) => userInfo && userInfo.userRoles;

export const isAdmin = (userInfo) => {
  return isUser(userInfo)
    && userInfo.userRoles.find(role => role.name === CONSTANTS.USER_ROLES.ADMIN);
}

export const isOrgAdmin = (userInfo) => {
  return isUser(userInfo)
    && userInfo.userRoles.find(role => role.name === CONSTANTS.USER_ROLES.ORG_ADMIN);
}
export const isAgency = (userInfo) => {
  return isUser(userInfo)
    && userInfo.userRoles.find(role => role.name === CONSTANTS.USER_ROLES.AGENCY);
}

export const getPositionAvailableOptions = (positions, formik) => {
  const {
    jobSpecification,
  } = formik.values;
  
  if (!(positions && positions.length)) {
    return [];
  }
  
  return positions.filter((position) => {
    let isValid = false;
    
    if (position.type.toUpperCase() === jobSpecification.toUpperCase()) {
      isValid = true;
    }
    
    return isValid;
  });
};


export const getMetaFieldsAvailableOptions = (fields, formik, positionOptions) => {
  const {
    position,
  } = formik.values;
  
  if (!(fields && fields.length)) {
    return [];
  }
  
  const availableFields = {};
  
  const selectedPosition = positionOptions && positionOptions.length && positionOptions.find(p =>p._id === position);
  
  if (selectedPosition && selectedPosition.fields && selectedPosition.fields.length) {
    selectedPosition.fields.forEach((field) => {
      if (!availableFields[field]) availableFields[field] = true;
    })
  }
  
  return fields.filter((field) => {
    let isValid = true;
    
    if (!(availableFields[field._id])) {
      isValid = false;
    }
    
    return isValid;
  });
};
