import React, { useEffect, useState } from 'react';
import { func, object } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authResendVerification } from '../../redux/actions/auth';
import { ReactComponent as Icon } from '../../assets/icons/mail.svg';
import AuthNotificationScreen from '../../components/auth/NotificationScreen';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  action: {
    height: 56,
    borderRadius: 6,
    border: `1px solid ${theme.colors.borderLight}`,
  },
}))

const propTypes = {
  authResendVerification: func,
  auth: object,
};

const TRANSLATION_PATH = 'auth';

function EmailConfirmation({ auth, authResendVerification }) {
  const [submitError, setSubmitError] = useState(null);
  const [email, setEmail] = useState();
  const { t: translate } = useTranslation();
  const t = (text, options) => text ? translate(`${TRANSLATION_PATH}.${text}`, options) : '';
  const classes = useStyles();
  
  const onForgotPassword = ()  => {
    setSubmitError(null);
    authResendVerification(email);
  }
  
  useEffect(() => {
    const submitError = !isEmpty(auth.error) && auth.error.message;
    
    setSubmitError(submitError);
  }, [auth]);
  
  useEffect(() => {
    const email = !isEmpty(auth.tempUserInfo) && auth.tempUserInfo.email;
    
    setEmail(email);
  }, [auth]);
  
  
  return (
    <>
      <AuthNotificationScreen
        error={submitError}
        icon={<Icon />}
        title={t(`Please_confirm_your_email`)}
        message={t(`Confirmation_email_sent`, { value: email })}
        action={
          <Button
            onClick={onForgotPassword}
            className={classes.action}
            variant="outlined"
            size="large"
          >
            {t('Resend_confirmation_link')}
          </Button>
        }
      />
    </>
  );
}

EmailConfirmation.propTypes = propTypes;

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  authResendVerification: bindActionCreators(authResendVerification, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmation);
