import * as c from '../constants/homepage';

export const specialistSearchRequest = () => ({
  type: c.SPECIALIST_SEARCH_REQUEST,
});

export const organisationSearchRequest = () => ({
  type: c.ORGANISATION_SEARCH_REQUEST,
});

export const sendForm = ({ dateString, hospitalName, name, email, phone, specialistValue, fieldValue, isAgreed }) => ({
  type: c.SEND_EMAIL_REQUEST,
  dateString,
  hospitalName,
  name,
  email,
  phone,
  specialistValue,
  fieldValue,
  isAgreed
})