const FIELD = [
  {
    _id: 'sdfhkdhfjskhdfhsdhn',
    name: "Neurologie"
  },
  {
    _id: 'sdkjfmjifjaskdkl',
    name: "Urologie"
  },
  {
    _id: 'fkjgkfjkflfkmkfvmf',
    name: "Cardiologie"
  },
];

export const META_INFORMATION = {
  field: FIELD
}
