const prefix = 'auth/';

export const LOG_IN_REQUEST = `${prefix}LOG_IN_REQUEST`;
export const LOG_IN_SUCCESS = `${prefix}LOG_IN_SUCCESS`;
export const LOG_IN_FAIL = `${prefix}LOG_IN_FAIL`;

export const SIGN_UP_REQUEST = `${prefix}SIGN_UP_REQUEST`;
export const SIGN_UP_SUCCESS = `${prefix}SIGN_UP_SUCCESS`;
export const SIGN_UP_FAIL = `${prefix}SIGN_UP_FAIL`;

export const FORGOT_PASSWORD_REQUEST = `${prefix}FORGOT_PASSWORD_REQUEST`;
export const FORGOT_PASSWORD_SUCCESS = `${prefix}FORGOT_PASSWORD_SUCCESS`;
export const FORGOT_PASSWORD_FAIL = `${prefix}FORGOT_PASSWORD_FAIL`;

export const RESEND_VERIFICATION_REQUEST = `${prefix}RESEND_VERIFICATION_REQUEST`;
export const RESEND_VERIFICATION_SUCCESS = `${prefix}RESEND_VERIFICATION_SUCCESS`;
export const RESEND_VERIFICATION_FAIL = `${prefix}RESEND_VERIFICATION_FAIL`;

export const RESEND_PASSWORD_REQUEST = `${prefix}RESEND_PASSWORD_REQUEST`;
export const RESEND_PASSWORD_SUCCESS = `${prefix}RESEND_PASSWORD_SUCCESS`;
export const RESEND_PASSWORD_FAIL = `${prefix}RESEND_PASSWORD_FAIL`;

export const RESET_PASSWORD_REQUEST = `${prefix}RESET_PASSWORD_REQUEST`;
export const RESET_PASSWORD_SUCCESS = `${prefix}RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_FAIL = `${prefix}RESET_PASSWORD_FAIL`;

export const GET_AUTH_USER_DETAILS_REQUEST = `${prefix}GET_AUTH_USER_DETAILS_REQUEST`;
export const GET_AUTH_USER_DETAILS_SUCCESS = `${prefix}GET_AUTH_USER_DETAILS_SUCCESS`;
export const GET_AUTH_USER_DETAILS_FAIL = `${prefix}GET_AUTH_USER_DETAILS_FAIL`;

export const LOG_OUT_REQUEST = `${prefix}LOG_OUT_REQUEST`;
