const prefix = 'Jobs/';

export const GET_ORGANISATION_JOB_LIST_REQUEST = `${prefix}GET_ORGANISATION_JOB_LIST_REQUEST`;
export const GET_ORGANISATION_JOB_LIST_SUCCESS = `${prefix}GET_ORGANISATION_JOB_LIST_SUCCESS`;
export const GET_ORGANISATION_JOB_LIST_FAIL = `${prefix}GET_ORGANISATION_JOB_LIST_FAIL`;

export const DELETE_ORGANISATION_JOB_REQUEST = `${prefix}DELETE_ORGANISATION_JOB_REQUEST`;
export const DELETE_ORGANISATION_JOB_SUCCESS = `${prefix}DELETE_ORGANISATION_JOB_SUCCESS`;
export const DELETE_ORGANISATION_JOB_FAIL = `${prefix}DELETE_ORGANISATION_JOB_FAIL`;

export const POST_ORGANISATION_JOB_REQUEST = `${prefix}POST_ORGANISATION_JOB_REQUEST`;
export const POST_ORGANISATION_JOB_SUCCESS = `${prefix}POST_ORGANISATION_JOB_SUCCESS`;
export const POST_ORGANISATION_JOB_FAIL = `${prefix}POST_ORGANISATION_JOB_FAIL`;

export const PUT_ORGANISATION_JOB_REQUEST = `${prefix}PUT_ORGANISATION_JOB_REQUEST`;
export const PUT_ORGANISATION_JOB_SUCCESS = `${prefix}PUT_ORGANISATION_JOB_SUCCESS`;
export const PUT_ORGANISATION_JOB_FAIL = `${prefix}PUT_ORGANISATION_JOB_FAIL`;

export const UPDATE_ORGANISATION_JOB_REQUEST = `${prefix}UPDATE_ORGANISATION_JOB_REQUEST`;
export const UPDATE_ORGANISATION_JOB_SUCCESS = `${prefix}UPDATE_ORGANISATION_JOB_SUCCESS`;
export const UPDATE_ORGANISATION_JOB_FAIL = `${prefix}UPDATE_ORGANISATION_JOB_FAIL`;