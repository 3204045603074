import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { green, yellow } from '@material-ui/core/colors';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import { findOrg } from '../../../../api';
import { EMAIL_PATTERN, GENDERS } from '../../../../constants/common';
import RenderCheckBoxField from '../../../common/formInputs/renderCheckBoxField';
import RenderSelectField from '../../../common/formInputs/renderSelectField';
import RenderTextField from '../../../common/formInputs/renderTextField';
import RenderAutoComplete from '../../../common/formInputs/renderAutoComplete';
import SubmitButton from '../../../common/formInputs/submitButton';
import { extractOptionsWithLanguage } from '../../../jobs/Form/Create/helpers';

const useStyles = makeStyles((theme) => {
	return {
		root: {
			padding: theme.spacing(11, 10, 10),
			[theme.breakpoints.only('xs')]: {
				padding: theme.spacing(8, 4),
			},
			'& h1': {
				fontSize: 26,
				letterSpacing: -0.45,
				marginBottom: theme.spacing(8),
			},
		},
		checkboxContainer: {
			marginBottom: 16,
			marginTop: 14,
			'& label *': {
				color: '#525f7a',
				fontSize: 14,
				fontFamily: `'OpenSans', 'sans-serif'`,
				lineHeight: 1.57,
			},
			'& svg': {
				fontSize: '24px !important',
			},
		},
		optional: {
			color: theme.colors.textLight,
			fontFamily: `'OpenSans', 'sans-serif'`,
			fontSize: 13,
		},
		submit: {
			backgroundColor: theme.colors.brandPrimary,
			height: 56,
			fontSize: 17,
			fontWeight: 600,
			marginTop: theme.spacing(1),
			minHeight: theme.spacing(13),
		},
	};
});

const TRANSLATION_PATH = 'auth';

const PasswordStrength = styled(Typography)`
  padding: 5px 0;
  color: ${props => {
	switch(props.strength) {
		case 'weak':
			return props.theme.palette.error.main
		case 'medium':
			return yellow[800]
		case 'strong':
			return green[800]
	}
}}
`;

const mapOptions = (info) => ({
	name: info.name,
	value: info._id,
})

const RegistrationForm = (props) => {
	const {
		isAgency,
		submitError,
		onSubmit,
	} = props;
	
	const { t: translate, i18n } = useTranslation();
	const t = (text) => text ? translate(`${TRANSLATION_PATH}.${text}`) : '';
	const classes = useStyles();
	const [organisations, setOrganisations] = useState([]);
	
	const fetchOrganisations = (searchTerm = '') => {
		setOrganisations([]);
		
		findOrg({ searchTerm })
			.then(({ data: { organisations } }) => {
				setOrganisations(organisations);
			})
			.catch(console.error)
	};
	
	const onSearchOrganisations = (searchTerm) => {
		fetchOrganisations(searchTerm);
	}
	
	useEffect(() => {
		if (!isAgency) {
			fetchOrganisations();
		}
		// eslint-disable-next-line
	}, [isAgency]);
	
	const orgValidation = isAgency ? yup.string : yup.object;
	
	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.matches(EMAIL_PATTERN, t(`Email_is_not_valid`))
			.required(t(`Email_is_not_valid`)),
		firstName: yup
			.string()
			.required(t(`First_name_not_valid`)),
		organisation: orgValidation()
			.required(t(`Institution_not_valid`)),
		gender: yup
			.string()
			.required(t(`Gender_not_valid`)),
		lastName: yup
			.string()
			.required(t(`Last_name_not_valid`)),
		password: yup
			.string()
			.required(t(`Password_is_not_valid`)),
		passwordConfirmation: yup
			.string().oneOf([yup.ref('password'), null], t(`Password_is_not_valid`))
			.required(t(`Password_is_not_valid`)),
		role: yup
			.string()
			.required(t(`Position_is_not_valid`)),
	});
	
	const formik = useFormik({
		initialValues: {
			email: '',
			firstName: '',
			organisation: '',
			phoneNumber: '',
			role: '',
			lastName: '',
			password: '',
			acceptTC: false,
			language: i18n.language,
		},
		validationSchema,
		onSubmit,
	});
	
	let isPasswordStrong = '';
	let passwordStrong = '';
	const passwordValue = formik.values.password;
	
	if(passwordValue.length<=6) {
		isPasswordStrong = 'weak';
		passwordStrong = t('Your_password_is_to_weak');
	} else if(passwordValue.length>6 && passwordValue.length<=10) {
		isPasswordStrong = 'medium';
		passwordStrong = t('You_password_is_not_enough_good');
	} else {
		isPasswordStrong = 'strong';
		passwordStrong = t('You_password_is_strong');
	}
	
	const renderInputContainer = (path, options = {}, sizes = { xs: 12 }, rest) => (
		<Grid item { ...sizes }>
			<RenderTextField
				formik={formik}
				name={path}
				{ ...options }
			/>
			{rest}
		</Grid>
	);
	
	return (
		<form
			className={`${classes.root}`}
			onSubmit={formik.handleSubmit}
		>
			<Grid
				container
				spacing={2}
			>
				<Grid item xs={12}>
					<Typography
						component="h1"
						variant="h2"
					>
						{t('Create account')}
					</Typography>
				</Grid>
				{
					renderInputContainer(
						'firstName',
						{
							placeholder: t('First_Name'),
						},
						{ xs: 12, sm: 6 },
					)
				}
				
				{
					renderInputContainer(
						'lastName',
						{
							placeholder: t('Last_Name'),
						},
						{ xs: 12, sm: 6 },
					)
				}
				
				<Grid item xs={12}>
					<RenderSelectField
						formik={formik}
						name="gender"
						label={t('Gender')}
						showInlineLabel
						options={extractOptionsWithLanguage(GENDERS, i18n)}
					/>
				</Grid>
				
				{
					isAgency
						? (
							renderInputContainer('organisation', {
								placeholder: t('Institution'),
							})
						)
						: (
							<Grid item xs={12}>
								<RenderAutoComplete
									formik={formik}
									name="organisation"
									placeholder={t('Institution')}
									options={organisations.map(mapOptions)}
									onInputChange={onSearchOrganisations}
								/>
							</Grid>
						)
				}
				
				
				{
					renderInputContainer('role', {
						placeholder: t('Position'),
					})
				}
				
				{
					renderInputContainer('email', {
						autoComplete: 'email',
						placeholder: t('Email_Address'),
						error: submitError,
					})
				}
				
				{
					renderInputContainer('phoneNumber', {
						placeholder: t('Phone_Number'),
						InputProps: {
							endAdornment: (
								<InputAdornment role="end" className={classes.optional}>
									({t('Optional')})
								</InputAdornment>
							)
						}
					})
				}
				
				{
					renderInputContainer(
						'password',
						{
							placeholder: t('Password'),
							type: 'password',
						},
						{ xs: 12 },
						formik.values.password
							? (
								<PasswordStrength
									strength={isPasswordStrong}
								>
									{passwordStrong}
								</PasswordStrength>
							)
							: null
					)
				}
				
				{
					renderInputContainer(
						'passwordConfirmation',
						{
							placeholder: t('Password_Confirm'),
								type: 'password',
						})
				}
				
				
				<Grid item xs={12}>
					<RenderCheckBoxField
						className={classes.checkboxContainer}
						formik={formik}
						name="acceptTC"
						label={t('Agree_Data_Usage')}
					/>
				</Grid>
				
				<Grid item xs={12}>
					<SubmitButton
						className={classes.submit}
						size="large"
					>
						{t('Create account')}
					</SubmitButton>
				</Grid>
			</Grid>
		</form>
	);
};

RegistrationForm.propTypes = {
	submitError: PropTypes.any,
	onSubmit: PropTypes.func.isRequired,
}

export default RegistrationForm;
