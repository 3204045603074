export const CONTRACT_TYPES = '/contract-types';
export const CONFIRM_EMAIL = '/auth/confirm-email';
export const DOCUMENTS = '/documents';
export const FORGOT_PASSWORD = '/auth/forgot-password';
export const IS_LOGGED_IN = '/auth/is-logged-in';
export const JOB = '/job';
export const JOB_TYPES = '/job-types';
export const JOB_SHIFT_TYPES = '/job-shift-type';
export const META_FIELDS = '/fields'
export const ORGANIZATION = '/organisation';
export const ORGANIZATIONS = '/organisations';
export const PERSON = '/person';
export const POSITION = '/positions';
export const RESEND_VERIFICATION = '/auth/resend-verification';
export const RESET_PASSWORD = '/auth/reset-password';
export const SEARCH_SPECIALITY = '/metainformation/job-titles';
export const SEND_FORM = '/metainformation/submit/send-email';
export const SIGNIN = '/auth/login';
export const SIGNUP = '/auth/registration';
export const SUBSCRIPTION = '/subscription';
export const USER = '/user';
export const USER_ROLES = '/user-roles';
